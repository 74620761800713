import React from "react"

import { Container } from 'reactstrap' 
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-about' 
import worldIcon from '../assets/images/aboutus-surpasing.png'
import reduser from '../assets/images/red-user.png' 
import redMission from '../assets/images/red-mission.png' 
import redVision from '../assets/images/red-vision.png' 
import redValue from '../assets/images/red-value.png' 
import qualityIcon from '../assets/images/quality-value.png' 
import safetyIcon from '../assets/images/safety-value.png' 
import honestyIcon from '../assets/images/honesty-value.png' 
import puntualityIcon from '../assets/images/puntuality-value.png' 
import techIcon from '../assets/images/tech-value.png' 
 
export default () => (
  <Layout>
    <SEO title="About Us" />
    <Slider title="ABOUT US" />
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	<div className="user-background" >
		<Container className="pt-4">
			<div className="row block">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
				<img className="redUser" src={reduser} alt="red icon user"/> 
				<h4 className="aboutH"><b>ABOUT US</b></h4> 
				<p className="aboutP">{'Get to know us'}</p>  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h4 className="grayH">{' '}</h4> 
						<p className="grayText">
							 Gutiérrez Trucking LLC. / Transportes Internacionales Escoboza S. De R.L. de C.V. 
							 were founded on June 02, 2002, initiating its operations on September of the same year, 
							counting with a fleet of 12 tractors, 12 vans and 2 flatbeds.<br/><br/>
							
							We provide Shuttle Services locally between Mexico and USA, specializing only on the 12 western states.<br/><br/>
							
							We have increased considerable our costumers as our operations and equipment. Today we have a fleed 
							of 125 tractors, 288 53" dry vans, 43 48" flatbeds, 2 panels, 2 24" bobtail trucks, 
							2 step deck for normal weight, 8 end dumps and 2 reefers, we now provide transportation 
							services in the northern states of Mexico, all USA and CANADA.<br/><br/>
						</p>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div>
	<div className="mision-background" >
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 locations-block locations-set">
				<img className="redMission" src={redMission} alt="red icon mission"/> 
				<h4 className="missionH">{'Mission'}</h4> 
				<p className="missionP">{'High Quality'}</p>  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row"> 
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h4 className="whiteH">{' '}</h4> 
						<p className="whiteText">
							  We area a company to transportation services, our mission is to <b>satisfy</b> our
							  customers needs in a <b>timely and secure</b> fashion, leveraging our expertise to deliver the
							  highest quality service.
						</p>
					</div> 
				</div>
			  </div> 
			</div>
		</Container>
	</div>
	<div className="vision-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="redVision" src={redVision} alt="red icon mission"/> 
				<h4 className="visionH">{'Vision'}</h4> 
				<p className="visionP">{'Leadership'}</p>  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<h4 className="grayH2">{' '}</h4> 
						<p className="grayText">
							Being the secure cargo transportation leader, domestic and internationally, providing an  
							<b> efficient and dedicated</b> service, assuring the development of the human talent that works with
							us for the company profit and the active participation towards our community. 
						</p>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div> 
	<div className="red-cross-background">
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"> 
					<img className="redValue" src={redValue} alt="red icon value"/> 
					<h4 className="valuesH">{'Values'}</h4>  
					<p className="valuesP">{'Service'}</p>   
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row espacioBlanco"> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="qualityIcon" src={qualityIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Quality'}</h4> 
						<p className="whiteVText">
							{'More than 17 years of experience in the field of cargo transportation '+
							 'support us and that why quality in our service is of primary importance.'  
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="safetyIcon" src={safetyIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Safety'}</h4> 
						<p className="whiteVText">
							{'We know the importance of security and we strive to maintrain our '+
							 'customers trust. We adhere to the standards and use tools that allow us'+  
							 'a maximum level of security in all the transfers we make.'  
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="honestyIcon" src={honestyIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Honesty'}</h4> 
						<p className="whiteVText">
							{'Our customers best interests comes first, this is why, we optimize the'+
							 'resources and adhere to the service policies efficiently complying with each'+  
							 'one of our compromises.'  
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="puntualityIcon" src={puntualityIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Punctuality'}</h4> 
						<p className="whiteVText">
							{'We understand the value and importance of time, supported by our'+
							 'logistics team we make sure to always deliver on time.' 
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="techIcon" src={techIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Technology'}</h4> 
						<p className="whiteVText">
							{'We maintain ourselves on the edge of technology, keeping the page with all'+
							 'the technological advances that we face everyday in this industry so that '+ 
							 'our shipments arrive in a timely and secure fashion.' 
							 } 
						</p>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div>  
  </Layout>
)
